<template>
  <v-stepper vertical v-model="step" class="mb-5">
    <v-stepper-step :complete="user.group?true:false" step="1">Who are you?</v-stepper-step>
      <v-stepper-content step="1">
        <v-card elevation="0" color ="transparent" width="50vw">
          <!--Show charity or giver account type-->
          <v-card-title >
            <p :class="'mx-auto text-center ' + `${$vuetify.breakpoint.mobile? 'text-h6':'text-h5'} mb-7`"
              >Pick Your Account Type</p>
          </v-card-title>
          <div class="text-center mb-3" >
            <v-btn block color= "blue" @click="selectGroup('charity')">Charity</v-btn>
          </div>
          <div class="text-center mb-3 text-h6">OR</div>
          <div class="text-center mb-3">
            <v-btn block color= "red" @click="selectGroup('giver')">Giver</v-btn>
          </div>
        </v-card>
      </v-stepper-content>
    <!--forms for registration
      - set label for each field
      - use 'solo' style for all fields (a UI style given by Vuetify)
      - set validation for each field before submission
      - validate field content only on blur i.e. when user remove the focus of the field
    -->
    <v-stepper-step :complete="state.isSubmitted" step="2">Account Details</v-stepper-step>
      <v-stepper-content step="2">
      <v-card  elevation="0" color ="transparent" :width="$vuetify.breakpoint.mobile?'60vw':'50vw'">
      <v-card-title> <p class="mx-auto text-center">Complete Your Registration</p></v-card-title>

      <!--use on_blur event to check if email has been used as it involves network operation-->
      <v-form ref='registerForm'>
        <EmailField :useremail.sync="user.email" :isValidated.sync="emailValidated" :exclusions="[]"></EmailField>

        <!--if a giver account is picked for registration-->
        <NameField label="First Name" :value.sync="user.firstName" :isApplicable="this.giverPicked"></NameField>
        <NameField label="Last Name" :value.sync="user.lastName" :isApplicable="this.giverPicked"></NameField>

        <!--if a charity account is picked for registration-->
        <NameField label="Charity Name" :value.sync="user.name" :isApplicable="this.charityPicked"></NameField>
        <PhoneField label="Phone" :value.sync="user.phoneNumber" :isApplicable="this.charityPicked"
            hint="Make sure it is a contact number in WhatsApp or Wechat"></PhoneField>
        <ChipBox label ="Charity Categories" hint="Create 1-5 to Identify Yourself"
          :choices.sync ="user.categories"  :isApplicable="this.charityPicked">
        </ChipBox>
        <GeolocationField :isApplicable="this.charityPicked" :geolocation.sync="user.geolocation"
          >
        </GeolocationField>
        <!--Password-->
        <v-text-field :type="passVisible.first?'text':'password'" solo label= "Password" v-model="user.password" autocomplete
          :rules="passwordValidator" hint="Contains min 8 characters (min 1 number and uppercase letter)"
          validate-on-blur :append-icon="passVisible.first?'mdi-eye-off':'mdi-eye'"
          @click="passVisible.first = !passVisible.first"></v-text-field>

        <v-text-field :type="passVisible.second?'text':'password'" solo label= "Password Confirmation" autocomplete
        :rules="passwordRechecker"
          validate-on-blur :append-icon="passVisible.second?'mdi-eye-off':'mdi-eye'"
           @click="passVisible.second = !passVisible.second"></v-text-field>
        <v-row justify="center">
        <v-btn :loading = "state.isSubmitting" @click='submit' class = "ml-1 mb-4 mr-7 primary">
          Register Now
        </v-btn>
        <v-btn class = "ml-1 mb-4 ml-7" @click='goBack(1)'>
          Go Back
        </v-btn>
        </v-row>
      </v-form>
      </v-card>
       <v-dialog persistent v-model="state.registrationCompleteDialog" max-width="80%">
            <v-card class="pa-8">
            <v-card-text>
            <v-alert dense outlined type="info" color="primary" class="text-center">
              <b>Success:</b> You'll be redirected to Home page for Login.</v-alert>
              <v-row justify="center">
                <v-btn class ="mx-auto" color="primary" @click="goHomePage">OK</v-btn>
              </v-row>
            </v-card-text>
            </v-card>
        </v-dialog>
      </v-stepper-content>
  </v-stepper>
</template>

<script>
import Vue from 'vue'
import EmailField from '@/components/fields/EmailField.vue'
import NameField from '@/components/fields/NameField.vue'
import PhoneField from '@/components/fields/PhoneField.vue'
import ChipBox from '@/components/fields/ChipBox.vue'
import GeolocationField from '@/components/fields/GeolocationField.vue'
export default {
  name: 'RegisterForm',
  components: {
    EmailField, NameField, PhoneField, ChipBox, GeolocationField
  },
  data () {
    return {
      step: '1',
      emailValidated: false,
      passVisible: {
        first: false,
        second: false
      },
      state: {
        isSubmitting: false,
        isSubmitted: false,
        registrationCompleteDialog: false
      },
      user: {
        group: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        name: '',
        categories: [],
        geolocation: {
          lat: 0.0,
          lng: 0.0
        }
      },
      /**
       * belows are validaters used for passwords, password fields are defined here due no reuse is needed
       */
      passwordValidator: [
        (input) => {
          if (input && input.length >= 8) {
            if (input.match(/[a-z]/) && input.match(/[A-Z]/) && input.match(/[0-9]/)) {
              return true
            } else {
              return 'Must be at least 8 characters contains number and uppercase letters'
            }
          } else {
            return 'Must be at least 8 characters contains number and uppercase letters'
          }
        }
      ],
      passwordRechecker: [
        (input) => {
          if (input === this.user.password) {
            return true
          }
          return 'Both password inputs are different'
        }
      ]
    }
  },
  computed: {
    giverPicked () {
      return this.user.group === 'giver'
    },
    charityPicked () {
      return this.user.group === 'charity'
    }
  },
  methods: {
    selectGroup (group) {
      this.user.group = group
      this.step = 2
    },
    /**
     * Submit the registration form if all fields' values are valid
     *  if registeration is successful, pop out a window for login. If login succeeds, opp-out window is closed
     *    and the parent window reloads and the user is logged in
     *  if registration fails, the user remains on the same page for correcitng validation errors
     */
    submit () {
      if (this.$refs.registerForm.validate() && this.emailValidated) {
        this.state.isSubmitting = true
        if (this.user.group === 'giver') {
          delete this.user.name
          delete this.user.phoneNumber
          delete this.categories
          delete this.geolocation
        } else if (this.user.group === 'charity') {
          delete this.user.firstName
          delete this.user.lastName
        }
        // console.log(this.user)
        // send post request for registration
        Vue.$publicApi.post('/account/register', this.user,
          {
            headers: { 'Content-type': 'application/json' }
          })
          .then((response) => {
            this.state.isSubmitting = false
            this.state.registrationCompleteDialog = true
          }).catch((err) => {
            this.state.isSubmitting = false
            console.log(err)
          })
          .finally(() => {
            // this.email.loading = false
          })
      } else {
        // console.log('fail to submit: contains validation errors')
      }
    },
    goHomePage () {
      const segment = Vue.$pathConverter.getFirstSegment()
      this.$router.push({ name: 'Home', params: { root: segment } })
    },
    /**
     * move back to previous step
     */
    goBack (step) {
      this.step = step
    }
  }
}
</script>
