<template>
<v-card class = "mx-auto" :width="$vuetify.breakpoint.mobile?'90vw':'60vw'">
  <v-card-title>Registration</v-card-title>
  <div class="d-flex justify-center" flat>
  <register-form></register-form>
  </div>
</v-card>
</template>

<script>
import RegisterForm from '@/components/RegisterForm.vue'
export default {
  components: {
    RegisterForm
  },
  data () {
    return {

    }
  }
}
</script>
